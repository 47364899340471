import React, { useState } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import TagPrompt from 'components/TagPrompt/TagPrompt';
import { type } from 'utils/type';
import { fontstack } from 'utils/fontstack';
import InstaIcon from './assets/instagram.svg';
import EmailIcon from './assets/email.svg';

const Wrapper = styled.div`
  position: relative;
  display: block;
  transition: opacity .2s ease;
  opacity: ${props => props.inactive ? 0.2 : 1};

  &:not(:last-child) {
    margin: 0 0 12px;
  }

  &:active {
    opacity: 0.2;
  }
`

const Label = styled.div`
  position: relative;
  ${fontstack.default}
  ${type('body')}
  margin: 0 0 0 12px;
`

const Email = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  & svg {
    width: 16px;
    height: 16px;
  }
`

const InstaLink = styled.a`
  display: flex;
  align-items: center;

  & svg {
    width: 16px;
    height: 16px;
  }
`

const Copied = styled(TagPrompt)`
  position: absolute;
  top: -5px;
  transform: translate(10px, 0px);

`

const ContactItem = ({children, insta, email, inactive, ...rest}) => {
  const [ isCopied, setIsCopied ] = useState(false);

  return (
    <Wrapper inactive={inactive} {...rest}>
      { insta && <InstaLink href={insta} target="_blank">
          <InstaIcon />
          <Label>
            {children}
          </Label>
        </InstaLink>}
      { email && <Email onClick={() => { 
        copy(email);
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 3000)
      }}>
          <EmailIcon />
          <Label>
            {children}
            { isCopied && <Copied>copied</Copied> }
          </Label>
         
        </Email> }
    </Wrapper>
  )
}

export default ContactItem;
