import React, { useState } from 'react';
import styled from 'styled-components';
import ContactItem from 'components/ContactItem/ContactItem';
import { SiteContext } from 'context/site-context';
import { SectionGutters } from 'components/Section/Section';
import { type } from 'utils/type';
import { fontstack } from 'utils/fontstack';
import { media } from 'utils/mediaQuery';

const Wrapper = styled(SectionGutters)`
  position: relative;
  width: 100%;
  padding-top: 4px;

  ${media.small`
    display: flex;
    padding-top: 43px;
    justify-content: flex-end;
  `}
`

const Half = styled.div`
  width: 100%;
  ${media.small`
    width: ${props => props.shift ? `calc(50% - ${props.shift}px)` : '50%'};
  `}
`

const Para = styled.p`
  ${fontstack.default}
  ${type('body')}
  width: 100%;
  max-width: 390px;
  margin: 16px 0 16px;
`

const Group = styled.div`
  margin: 40px 0 0;

  &:last-child {
    margin: 40px 0;
  }

  ${media.small`
    margin: 32px 0 0;

    &:last-child {
      margin: 32px 0 0;
    }

  `}
`

const Header = styled.h2`
  ${fontstack.default}
  ${type('metadata')}
`

const Contacts = styled.div`
  margin: 16px 0 0;
  display: flex;

  & > div:first-child {
    width: 50%;
  }

  ${media.small`
    display: block;
    & > div:first-child {
      width: auto;
    }

  `}
`

const Info = ({data}) => {
  const { InfoShiftValue } = React.useContext(SiteContext);
  const [ Active, setActive ] = useState(null);

  const content = data;
  return (
    <Wrapper>
      <Half shift={InfoShiftValue}>
        <Para>{content?.description}</Para>
        <Group>
          <Header>Prints</Header>
          <Para>{content?.prints}</Para>
        </Group>
        <Group>
          <Header>Contact</Header>
          <Contacts>
            <ContactItem insta="https://www.instagram.com/igmirien" onMouseEnter={()=>setActive("insta")} onMouseLeave={()=>setActive(null)} inactive={Active && Active !== "insta" ? true : false}>Instagram</ContactItem>
            <ContactItem email="abdela@igmirien.com" onMouseEnter={()=>setActive("email")} onMouseLeave={()=>setActive(null)} inactive={Active && Active !== "email"}>abdela@igmirien.com</ContactItem>
          </Contacts>
        </Group>
      </Half>
    </Wrapper>
  )
}

export default Info;
