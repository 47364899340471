import React from 'react';
import styled from 'styled-components';
import { type } from 'utils/type';
import { fontstack } from 'utils/fontstack';
import CheckIcon from './assets/check-circle.svg';

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  background: rgb(var(--brand-white));
  border-radius: 2px;
  padding: 5px 6px;
  align-items: center;

  &:not(:last-child) {
    margin: 0 0 12px;
  }

  &:active {
    opacity: 0.2;
  }
`

const Label = styled.div`
  ${fontstack.default}
  ${type('metadata')}
  margin: 0 0 0 5px;
`

const TagPrompt = ({children, ...rest}) => {

  return (
    <Wrapper {...rest}>
      <CheckIcon />
      <Label>
        {children}
      </Label>
    </Wrapper>
  )
}

export default TagPrompt;
